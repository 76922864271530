@font-face {
  font-family: 'Rubik';
  src: url('../../../public/Assets/font/Rubik-Black.woff2') format('woff2'),
    url('../../../public/Assets/font/Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../../public/Assets/font/Rubik-Bold.woff2') format('woff2'),
    url('../../../public/Assets/font/Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../../public/Assets/font/Rubik-Medium.woff2') format('woff2'),
    url('../../../public/Assets/font/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../../public/Assets/font/Rubik-Regular.woff2') format('woff2'),
    url('../../../public/Assets/font/Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../../public/Assets/font/Rubik-Light.woff2') format('woff2'),
    url('../../../public/Assets/font/Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
